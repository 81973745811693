.usados {
  .intro {
    height: 100px;
    background-image: url("../../img/divisor_black.png");
    background-position: center center;
    background-repeat: repeat-x;

    @media (max-width: 768px) {
      background-image: none;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 4px solid #000;
      margin-top: 40px;
    }
  }

  .notebook {
    min-height: 500px;

    .w-100-mobile {
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }

    h2 {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 94%;

      /* or 45px */

      color: #39a935;

      @media (max-width: 768px) {
        background-image: none;
        font-size: 39px;
      }

      span {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 94%;

        /* or 45px */
        @media (max-width: 768px) {
          background-image: none;
          font-size: 39px;
        }

        color: #000000;
      }
    }

    .entrega {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      color: #000000;
    }
  }
}
