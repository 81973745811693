*,
*:before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  color: $monti-black;
}

body {
  font-family: $fontFamily;
}
img {
  max-width: 100%;
}

.title-oport {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 94%;

  /* or 19px */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
  padding: 0;
  margin: 0;
}

.btn-black {
  background-color: white;
  color: #000;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  border-radius: 15px;

  border: 2px solid #000;

  &.active {
    background-color: $monti-black;
    color: #fff;
  }
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active {
  background-color: $monti-black;

  border-color: white;
  color: white;
  font-weight: bold;
}
.hover-shadow:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.8);
  transform: scale(1.05);
}
.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #ccc !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.cuentasBancarias {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 16px !important;
      font-weight: 400 !important;
    }

    h4 {
      font-size: 18px !important;
      font-weight: 700 !important;
    }

    .card-body {
      padding-top: 5px !important;
    }
  }
}
