.header {
  background: #000;

  padding: 28px;

  .navbar-dark {
    background: #000 !important;
  }

  .navbar-toggler {
    color: #fff !important;
  }
}

.stickyHeader {
}

.btnHeader {
  font-family: $fontFamily;

  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;

  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  color: #ffffff;

  &.outline {
    border-radius: 10px;
    border: 2px solid #fff;
    padding: 5px 15px;
  }
}

.btnHeader:hover {
  text-decoration: none;

  color: #fff;
}

.btnHeader.outline:hover {
  text-decoration: none;
  background-color: #fff;

  color: #000;
}

.botonera {
  background-color: #fff;
  border-bottom: 2px solid #000;
  padding: 0px;

  .container-btn {
    width: 150px;
    text-align: center;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .link {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;

    /* identical to box height */
    text-transform: uppercase;

    color: #000000;
    text-decoration: none;
  }
  .link:hover {
    font-weight: 700;
  }
}

.botonera-mobile {
  background-color: #000;

  padding: 0px;

  .link {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;

    /* identical to box height */
    text-transform: uppercase;

    color: #fff;
    text-decoration: none;
  }
  .link:hover {
    font-weight: 700;
  }
}

.navbar-brand {
  img {
    max-width: 200px !important;
  }
}
