.servicios {
  .intro {
    height: 100px;
    background-image: url("../../img/divisor_black.png");
    background-position: center center;
    background-repeat: repeat-x;

    @media (max-width: 768px) {
      height: auto;
      background-image: none;
    }

    h1 {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 94%;
      color: #000000;
      background-color: white;
      padding: 5px 40px;

      @media (max-width: 768px) {
        font-size: 34px;
      }
    }
  }

  .subintro {
    h2 {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      /* identical to box height */

      color: #000000;
      background-color: white;
      padding: 0px 40px;
    }
  }
}

.servicio {
  width: 390px;
  height: 233px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0px;
    width: 390px;
    height: 233px;
  }

  .logo {
    position: relative;
    transition: transform 0.3s ease;
    transform: translateY(0px);
  }

  .overlay {
    display: none;
    padding: 0 45px 10px 45px;

    p {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;

      color: #ffffff;
    }

    a {
      font-family: $fontFamily;

      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-decoration: none;
      border-radius: 10px;
      border: 1px solid #fff;
      text-align: center;
      padding: 5px 20px;
      color: #ffffff;
    }
  }

  &.rentauto {
    background-image: url("../../img/marcas/servicios/rentauto.png");
    background-size: cover;
    opacity: 1;
    transition: 0.3s;
  }

  &.rentauto:hover {
    opacity: 0.8;

    .logo {
      top: 40px;
      position: relative;
      transform: translateY(-190px);
      transform: translateX(-50px);
      width: 1px !important;
      height: 1px !important;

      img {
        width: 1px !important;
        height: 1px !important;
      }
    }

    .overlay {
      display: flex;
      flex-direction: column;
    }
  }

  &.quicklane {
    background-image: url("../../img/marcas/servicios/quicklane.png");
    background-size: cover;
    opacity: 1;
    transition: 0.3s;
  }

  &.quicklane:hover {
    opacity: 0.8;

    .logo {
      top: 40px;
      position: relative;
      transform: translateY(-190px);
      transform: translateX(-50px);
      width: 1px !important;
      height: 1px !important;

      img {
        width: 1px !important;
        height: 1px !important;
      }
    }

    .overlay {
      display: flex;
      flex-direction: column;
    }
  }

  &.posventa {
    background-image: url("../../img/marcas/servicios/posventa.png");
    background-size: cover;
    opacity: 1;
    transition: 0.3s;
  }

  &.posventa:hover {
    opacity: 0.8;

    .logo {
      top: 40px;
      position: relative;
      transform: translateY(-190px);
      transform: translateX(-50px);
      width: 1px !important;
      height: 1px !important;

      img {
        width: 1px !important;
        height: 1px !important;
      }
    }

    .overlay {
      display: flex;
      flex-direction: column;
    }
  }

  &.checkpoint {
    background-image: url("../../img/marcas/servicios/checkpoint.png");
    background-size: cover;
    opacity: 0.8;
    transition: 0.3s;
  }

  &.checkpoint:hover {
    opacity: 1;

    .logo {
      top: 40px;
      position: relative;
      transform: translateY(-190px);
      transform: translateX(-50px);
      width: 1px !important;
      height: 1px !important;

      img {
        width: 1px !important;
        height: 1px !important;
      }
    }

    .overlay {
      display: flex;
      flex-direction: column;
    }
  }
}
